<template>
  <div class="autocompounder-container">

    <div class="connect">
      <div v-if="!connected || !web3" class="button" @click="initWalletConnect()">Connect wallet</div>
      <div v-else>
        ✅ connected {{ shortAddress }}
        <div v-if="chainID != 42161">❌ please switch to Arbitrum network and refresh</div>
        <div v-else>✅ Arbitrum network</div>
      </div>
    </div>

    <div class="spacer" style="height: 40px;"/>

    <div v-if="!connected">
      <h2>⚠️ Please connect your wallet.</h2>
    </div>
    <div v-else-if="chainID != 42161">
      <h2>⚠️ Please switch to Arbitrum network.</h2>
    </div>

    <div v-else class="connected">

      <div>
        <h2>Info</h2>
      </div>
      <div class="stats">
        <div class="left">
          <div class="label">Total assets in vault:</div>
          <div class="label">Total compounded:</div>
          <div class="spacer" style="height: 15px;"/>
          <div class="label">Pending rewards:</div>
        </div>
        <div class="right">
          <div class="value">{{ totalAssets | fromWei }} GLP</div>
          <div class="value">{{ totalHarvested | fromWei }} ETH</div>
          <div class="spacer" style="height: 15px;"/>
          <div class="value">
            {{ pendingRewards | fromWei }} ETH
            <div v-if="showRewardAnimation" class="rewardAnimation">💸</div>
          </div>
        </div>
      </div>
      <div v-if="advanced" class="actions">
        <div class="spacer" style="height: 40px;"/>
        <h3>Manual Actions</h3>
        <div class="button" @click="harvestTransferAndCompound()">harvestAndCompound</div>
        <div class="spacer" style="height: 20px;"/>
        <div v-if="strategyBalance > 0" class="info">
          last GLP mint/purchase: {{ lastGlpPurchase }}<br/>
          compound possible after > 15min
        </div>
        <div v-else class="info">
          Nothing to compound.
        </div>
        <div class="button" :class="{ disabled : strategyBalance <= 0}" @click="compound()">Compound</div>
      </div>

      <div class="spacer" style="height: 40px;"/>

      <div>
        <h2>Deposit</h2>
      </div>
      <div class="deposit">
        <div class="balance">
          Your GLP balance: {{ balance | fromWei }} GLP<br/>
          Your aGLP balance (vault shares): {{ aGLPBalance | fromWei }} aGLP (≈{{ aGLPValue  | fromWeiRounded }} GLP)
        </div>
        <div class="spacer" style="height: 40px;"/>
        <div class="info" style="text-align:center;">
          🕐 Newly purchased GLP can only be deposited <u>15min after</u> purchase due to GLP mint-lock.
        </div>
        <div class="amount">
          <div class="max" @click="depositAmount = web3.utils.fromWei(balance)">max</div>
          <input class="input" v-model="depositAmount">
        </div>

        <div class="spacer" style="height: 20px;"/>

        <div class="actions">
          <div v-if="!approved" class="button" @click="approveGLP()">Approve GLP</div>
          <div class="spacer" style="height: 10px;"/>
          <div v-if="!approved" class="info">
            ⚠️ Amount exceeds allowance, approve first.
          </div>
          <div class="button" :class="{ disabled : !approved || depositAmount <= 0 }" @click="depositGLP()">Deposit GLP</div>
        </div>

        <div class="spacer" style="height: 50px;"/>

        <div class="add-token">
          <div @click="addToken()">
            Add aGLP to MetaMask
          </div>
        </div>
      </div>

      <div class="spacer" style="height: 40px;"/>
      <div>
        <h2>Redeem</h2>
      </div>
      <div class="redeem">
        <div class="balance">
          Your aGLP balance (vault shares): {{ aGLPBalance | fromWei }} aGLP (≈{{ aGLPValue | fromWeiRounded }} GLP)
        </div>
        <div class="spacer" style="height: 40px;"/>
        <div class="amount">
          <div class="max" @click="redeemAmount = web3.utils.fromWei(aGLPBalance)">max</div>
          <input class="input" v-model="redeemAmount">
        </div>
        <div v-if="aGLPValue != 0 && aGLPBalance != 0 && redeemAmount != 0" class="info" style="text-align: center;">
          ≈ {{ (aGLPValue/aGLPBalance*redeemAmount).toFixed(2) }} GLP
        </div>
        <div class="spacer" style="height: 20px;"/>
        <div v-if="aGLPBalance <= 0" class="info" style="text-align: center;">
          You do not have aGLP shares in your connected wallet to redeem.
        </div>
        <div class="withdraw">
          <div class="actions">
            <div class="button" :class="{ disabled : aGLPBalance <= 0}" @click="redeem()">Redeem Shares</div>
          </div>
        </div>
      </div>

    </div>

    <div class="spacer" style="height: 90px;"/>

    <div class="footer-info">
        <div class="token-addr">
            aGLP token address: 
            <CopyableText data="0xfF6b69B78DF465bf7e55D242fD11456158D1600A">
                <span style="font-family:monospace;">0xfF6b69B78DF465bf7e55D242fD11456158D1600A</span>
            </CopyableText>

            <a href="https://arbiscan.io/address/0xff6b69b78df465bf7e55d242fd11456158d1600a#code" target="_blank" style="position:relative; top: 5px; left: 5px;">
                <svg class="feather feather-external-link" width="23" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"/>
                    <polyline points="15 3 21 3 21 9"/>
                    <line x1="10" x2="21" y1="14" y2="3"/>
                </svg>  
            </a>
        </div>
    </div>

    <modal v-if="showDisclaimer" :unclosable="true" @close="showDisclaimer = false">
      <div class="disclaimer">
        <h2>Disclaimer</h2>
        <div class="info">Sorry, given the regulatory bs going on we have no choice 🤮</div>
        <div class="spacer" style="height: 20px;"/>

        Risks of using Unstoppable<br/>

        Using Unstoppable products doesn't come without risks. 
        Before making a deposit and interacting with one of our dApps, 
        it is best to research and understand the risks involved. 
        You are using Unstoppable voluntarily at any time and 
        by all means. When interacting or attempting to interact 
        (in both cases, “interacting”) with Unstoppable, you 
        confirm that you understand and agree that you are doing it 
        fully on your own behalf and risk and by acknowledging these terms:

   		  <p>
          Nature of Unstoppable: The project is completely 
          decentralized and owned by no one, Unstoppable is 
          not involved in any transactions, whether as an intermediary, 
          counterparty, advisor or otherwise. Unstoppable never
          takes custody or has access to your or anybodys assets.
        </p>
        <p>
          You are not a US Person; you are not a resident, national, or 
          agent of Antigua and Barbuda, Algeria, Bangladesh, Bolivia, 
          Belarus, Burundi, Burma (Myanmar), Cote D’Ivoire (Ivory Coast), 
          Crimea and Sevastopol, Cuba, Democratic Republic of Congo, Ecuador, 
          Iran, Iraq, Liberia, Libya, Magnitsky, Mali, Morocco, Nepal, 
          North Korea, Somalia, Sudan, Syria, Venezuela, Yemen, Zimbabwe 
          or any other country to which the United States, the United Kingdom 
          or the European Union embargoes goods or imposes similar sanctions; 
          you are not a member of any sanctions list or equivalent maintained 
          by the United States government, the United Kingdom government, 
          the European Union, or the United Nations; you do intend to transact 
          with any Restricted Person or Sanctions List Person; you do not, and 
          will not, use VPN software or any other privacy or anonymization 
          tools or techniques to circumvent, or attempt to circumvent, any 
          restrictions.
        </p>
        <div class="button" @click="confirmDisclaimer()" style="text-align:center;">
          I fully understand: <br/>let me in at my own risk
        </div>
      </div>
    </modal>

  </div>
</template>

<script>
import WalletConnectProvider from '@walletconnect/web3-provider'
import Web3 from 'web3'
import Web3Modal from 'web3modal'

import vaultABI from '@/abis/vault.json'
import strategyABI from '@/abis/strategy.json'
import erc20ABI from '@/abis/erc20.json'
import rewardTrackerABI from '@/abis/rewardTracker.json'

import CopyableText from '@/components/utility/CopyableText.vue'
import Modal from '@/components/utility/Modal.vue'

const BN = Web3.utils.BN

const txOpts = {
  maxPriorityFeePerGas: "0",
  maxFeePerGas: "100000000"
}

export default {
  name: 'Autocompounder',
  components: {
    CopyableText,
    Modal,
  },
  data() {
    return {
      web3: null,
      connected: false,
      chainID: null,
      provider: null,
      
      vaultContractAddress: '0xfF6b69B78DF465bf7e55D242fD11456158D1600A',
      strategyContractAddress: '0xaF51BA9524a5cd9C4Ff998608Bd5f1E1C8c855C9',
      glpContractAddress: '0x1aDDD80E6039594eE970E5872D247bf0414C8903',
      sGLPContractAddress: '0x2F546AD4eDD93B956C8999Be404cdCAFde3E89AE',
      rewardTrackerContractAddress: '0x4e971a87900b931fF39d1Aad67697F49835400b6',

      vault: null,
      strategy: null,
      glp: null,
      sGLP: null,
      rewardTracker: null,

      totalAssets: 0,
      totalHarvested: 0,
      totalCompounded: 0,
      pendingRewards: 0,
      strategyBalance: 0,

      address: '',
      balance: 0,

      allowance: 0,

      aGLPBalance: 0,
      aGLPValue: 0,

      lastPurchaseTimestamp: null,
      
      depositAmount: 0,
      redeemAmount: 0,
      
      interval: null,
      showRewardAnimation: false,
      animationTimeout: null,

      showDisclaimer: true,
    }
  },
  created() {
    let disclaimerConfirmed = window.localStorage.getItem('[Unstoppable:DeFi]::DisclaimerConfirmed')
    this.showDisclaimer = disclaimerConfirmed != 'true' // string
  },
  mounted() {
    this.initWalletConnect()
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  methods: {
    refresh() {
      this.fetchBalance()
      this.fetchAllowance()
      this.fetchVaultAssets()
      this.fetchTotalHarvest()
      this.fetchTotalCompounded()
      this.fetchRewards()
      this.fetchLastPurchaseTimestamp()
      this.trackRewards()
    },
    async fetchBalance() {
      let b = await this.glp.methods.balanceOf(this.address).call()
      this.balance = b
      this.depositAmount =  this.web3.utils.fromWei(this.balance)

      let s = await this.glp.methods.balanceOf(this.strategyContractAddress).call()
      this.strategyBalance = s

      let a = await this.vault.methods.balanceOf(this.address).call()
      this.aGLPBalance = a
      this.redeemAmount = this.web3.utils.fromWei(this.aGLPBalance)
      let p = await this.vault.methods.previewRedeem(this.aGLPBalance).call()
      this.aGLPValue = p
    },
    async fetchAllowance() {
      let allowance = await this.sGLP.methods.allowance(this.address, this.vaultContractAddress).call()
      this.allowance = allowance
    },
    async approveGLP() {
      let amount = this.web3.utils.toWei(this.depositAmount)
      await this.sGLP.methods.approve(this.vaultContractAddress, amount).send(txOpts)
      this.fetchAllowance()
    },
    async depositGLP() {
      if(!this.approved || this.depositAmount <= 0) {
        return
      }
      let amount = this.web3.utils.toWei(this.depositAmount)
      await this.vault.methods.deposit(amount, this.address).send(txOpts)
      this.refresh()
    },
    async redeem() {
      let amount = this.web3.utils.toWei(this.redeemAmount)
      await this.vault.methods.redeem(amount, this.address, this.address).send(txOpts)
      this.refresh()
    },
    async harvestTransferAndCompound() {
      await this.vault.methods.harvestTransferAndCompound().send(txOpts)
      this.refresh()
    },
    async compound() {
      await this.strategy.methods.compound().send(txOpts)
      this.fetchBalance()
    },
    async refreshStats() {
      this.fetchVaultAssets()
      this.fetchTotalHarvest()
      this.fetchTotalCompounded()
      this.fetchRewards()
    },
    async fetchVaultAssets() {
      let assets = await this.vault.methods.totalAssets().call()
      this.totalAssets = assets
    },
    async fetchTotalHarvest() {
      let harvested = await this.vault.methods.totalHarvested().call()
      this.totalHarvested = harvested
    },
    async fetchTotalCompounded() {
      let compounded = await this.strategy.methods.totalPurchased().call()
      this.totalCompounded = compounded
    },
    async trackRewards() {
      if(this.interval) return
      this.fetchRewards()
      this.interval = setInterval(() => {
        this.refreshStats()
      }, 1000);
    },
    async fetchRewards() {
      let pendingRewards = await this.rewardTracker.methods.claimable(this.vaultContractAddress).call()
      this.pendingRewards = pendingRewards
    },
    async fetchLastPurchaseTimestamp() {
      let timestamp = await this.strategy.methods.lastPurchaseTimestamp().call()
      this.lastPurchaseTimestamp = timestamp
    },

    async addToken() {
      this.provider.sendAsync({
        method: 'metamask_watchAsset',
        params: {
          'type': 'ERC20',
          'options': {
            'address': this.vaultContractAddress,
            'symbol': 'aGLP',
            'decimals': 18,
            'image': 'https://glp.unstoppable.ooo/aglp_logo.png',
          },
        },
        id: Math.round(Math.random() * 100000),
      })
    },
    async changeNetwork () {
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0xA4B1' }]
        })
        this.initContracts()
      } catch (error) {
        // This error code indicates that the chain has not been added to MetaMask.
        if (error.code === 4902) {
          try {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: '0xA4B1', // A 0x-prefixed hexadecimal string
                  chainName: 'Arbitrum',
                  nativeCurrency: {
                    symbol: 'AETH', // 2-6 characters long
                    decimals: 18
                  },
                  rpcUrls: ['https://arb1.arbitrum.io/rpc'],
                  blockExplorerUrls: ['https://arbiscan.io']
                }
              ]
            })
            this.changeNetwork()
          } catch (addError) {
            // handle "add" error
          }
        }
        // handle other "switch" errors
      }
    },

    async initContracts() {
      const glp = new this.web3.eth.Contract(erc20ABI, this.glpContractAddress, { from: this.address })
      this.glp = glp

      const sGLP = new this.web3.eth.Contract(erc20ABI, this.sGLPContractAddress, { from: this.address })
      this.sGLP = sGLP

      const vault = new this.web3.eth.Contract(vaultABI, this.vaultContractAddress, { from: this.address })
      this.vault = vault

      const strategy = new this.web3.eth.Contract(strategyABI, this.strategyContractAddress, { from: this.address })
      this.strategy = strategy

      const rewardTracker = new this.web3.eth.Contract(rewardTrackerABI, this.rewardTrackerContractAddress, { from: this.address })
      this.rewardTracker = rewardTracker
      
      this.refresh()
    },

    async initWalletConnect() {
      if(!window.ethereum) {
        alert('No web3 wallet found. Please install a wallet such as MetaMask.')
        return
      }

      const providerOptions = {
        walletconnect: {
          package: WalletConnectProvider, // required
          options: {
            rpc: {
              42161: 'https://arb-mainnet.g.alchemy.com/v2/_l-6Gj5p08Xp9uNHP3Fo9ZIA95lnN0Lo', 
            }
          }
        }
      }

      const web3Modal = new Web3Modal({
        // network: "mainnet", // optional
        cacheProvider: true, // optional
        providerOptions, // required
        theme: "dark",
      })

      const provider = await web3Modal.connect()
      this.provider = provider

      // Subscribe to chainId change
      provider.on("chainChanged", async (chainID) => {
        console.log('chainChanged', chainID)
        clearInterval(this.interval) // stop fetching rewards
        this.chainID = chainID
      });

      // Subscribe to provider connection
      provider.on("connect", (info) => {
        console.log(info);
      });

      // Subscribe to provider disconnection
      provider.on("disconnect", (error) => {
        console.log(error)
        this.connected = false
      });

      provider.on("accountsChanged", (accounts) => {
        this.address = accounts[0]
        if(this.chainID == 42161) {
          this.initContracts()
        }
      });


      const web3 = new Web3(provider)
      this.web3 = web3
      
      this.connected = true
      console.log('connected')
      
      let accounts = await this.web3.eth.getAccounts()
      this.address = accounts[0]

      const chainID = await this.web3.eth.getChainId()
      console.log('chainID', chainID)
      this.chainID = chainID
      if(this.chainID == 42161) {
        this.initContracts()
      }
    },

    confirmDisclaimer() {
      this.showDisclaimer = false
      window.localStorage.setItem('[Unstoppable:DeFi]::DisclaimerConfirmed', 'true')
    }
  },
  computed: {
    approved() {
      let input = Web3.utils.toWei(String(this.depositAmount))
      let allowance = Web3.utils.toBN(this.allowance)
      return allowance.gte(Web3.utils.toBN(input))
    },
    advanced() {
      const urlParams = new URLSearchParams(window.location.search)
      return urlParams.get('advanced')
    },
    lastGlpPurchase() {
      const rtf = new Intl.RelativeTimeFormat('en', {
        numeric: 'auto',
      });
      const oneMinuteInMs = 1000 * 60;
      const minDifference = Math.round(
        (this.lastPurchaseTimestamp*1000 - new Date().getTime()) / oneMinuteInMs,
      );

      return rtf.format(minDifference, 'minute');
    },
    shortAddress() {
      return this.address.substring(0,5) + '...' + this.address.substring(this.address.length-4)
    }
  },
  watch: {
    pendingRewards() {
      this.showRewardAnimation = true
      this.animationTimeout = setTimeout(() => this.showRewardAnimation = false, 1000)
    },
    chainID() {
      if(this.chainID != 42161) {
        this.changeNetwork()
      }
    }
  },
  filters: {
    fromWei(v) {
      if(v == 0) return 0
      return Web3.utils.fromWei(v)
    },
    fromWeiRounded(v) {
      if(v == 0) return 0
      return (v / 1e18).toFixed(2)
    }
  }
}
</script>

<style lang="scss" scoped>

$primary: #192baf;
$secondary: #40bcc8;

$dark: #1B4965;
$highlight: #e7196f;
$highlightDarker: #99134b;

.autocompounder-container {
  display: inline-block;
  margin: auto;
  padding-bottom: 100px;
}

.stats, .deposit, .redeem {
  background-image: linear-gradient(22deg, $primary 20%, $highlightDarker);
  color: white;
  padding: 50px;
  border-radius: 5px;
  text-align: left;
  font-weight: bold;
  box-shadow: 4px 4px 20px rgba($dark, 0.5);
  
  .actions {
    text-align: center;
  }
}

.stats {
  display: flex;

  .right, .left {
    flex: 1;

    .label, .value {
      position: relative;
      line-height: 1.8em;
    }
  }
}

.deposit {
  background-image: linear-gradient(45deg, $primary 30%, $highlightDarker);
  position: relative;
}

.redeem {
  background-image: linear-gradient(135deg, $primary 30%, $highlightDarker);
}

.amount {
  position: relative;
}

.max {
  border: 1px solid $primary;
  border-radius: 3px;
  font-size: 13px;
  display: inline-block;
  padding: 2px 5px;
  position: absolute;
  right: 10px; 
  top: 12px;
  color: $primary;
  cursor: pointer;
  transition: 0.1s all;

  &:hover {
    background-color: $primary;
    color: white;
  }
}

.button {
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #AA1155;
  color: white;
  font-weight: bold;
  width: 200px;
  margin: auto;
  transition: 0.1s transform;

  &:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
}

.amount {
  max-width: 300px;
  margin: auto;
}

.info {
  font-size: 12px;
}

.disabled {
  background-color: grey !important;
  cursor: not-allowed !important;
  &:hover {
    transform: none;
  }
}

.add-token {
  // position: absolute;
  // bottom: 20px;
  // right: 20px;
  color: white;
  cursor: pointer;
  text-align: right;
}

.rewardAnimation {
  position: absolute;
  top: 0;
  right: 0px;
  font-size: 20px;
  animation: fly 1s;
}


@keyframes fly {
  0% { top: 0; right: 0; transform: scale(0);}
  // 25% { top: -25px; right: -10px; }
  // 50% { top: -35px; right: -5px; }
  75% { opacity: 1; }
  100% { top: -40px; right: -40px; opacity: 0; transform: scale(1); }
}

.disclaimer {
  padding: 30px;
  text-align: left;
  font-size: 14px;
}
</style>
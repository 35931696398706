<template>
  <div id="app">
    <div class="banner">
      <img src="@/assets/banner01.png"/>
    </div>
    <div class="spacer" style="height: 50px"/>
    <div class="header">
      <h1>Unstoppable GLP Autocompounder</h1>
      <h2>Zero fees. Free. Forever</h2>
    </div>
    <div class="coop">
      <img src="@/assets/arbitrum_logo.png"/>
       🤝
      <img src="@/assets/gmx_logo.svg"/>
       🤝
      <img src="@/assets/uf_logo_small.png"/>
      
    </div>
    <div class="spacer" style="height: 50px"/>
    <div class="article">
      Read more about it in our
      <a href="https://unstoppablefinance.medium.com/zero-fees-free-forever-75d1bcbcf41f" target="_blank">
        Release Article.
      </a>
      <div class="spacer" style="height: 20px"/>
      📃 And if you need help, here is the 
      <a href="https://unstoppablefinance.medium.com/releasing-the-unstoppable-glp-autocompounder-41a6d3361e79" target="_blank">
        How to.
      </a> 
    </div>
    <div class="spacer" style="height: 50px"/>
    <div class="main">
      <Autocompounder/>
    </div>
    <div class="spacer" style="height: 50px"/>
    <div class="footer">
      by <a href="https://unstoppable.ooo" target="_blank">unstoppable.ooo</a>
    </div>
  </div>
</template>

<script>
import Autocompounder from './components/Autocompounder.vue'

export default {
  name: 'App',
  components: {
    Autocompounder
  }
}
</script>

<style lang="scss">
html, body {
    background: rgb(25,43,175);
    background: linear-gradient(90deg, rgba(25,43,175,1) -30%, rgb(0, 0, 0) 20%, rgb(0, 0, 0) 80%, rgba(25,43,175,1) 130%);
}

a {
    color: inherit;
    text-decoration: underline;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
}

.input {
  display: block;
  margin: 8px 0px;
  padding: 8px 10px;
  width: 100%;
  border: 1px solid #192baf;
  border-radius: 3px;
  transition: box-shadow 0.3s;
  color: black;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: 1.8;
  box-sizing: border-box;
  
  &:focus {
    outline: none;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  }
}  

.banner {
  // height: 150px;

  img {
    max-height: 100%;
    max-width: 100%;
  }
}

.coop {
  font-size: 30px;
  img {
    height: 30px;
  }
}

</style>

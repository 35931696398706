<template>
  <div class="copyabletext-container">
    <div class="copyable-text" :class="{pulse: copyAnimation}">
      <slot></slot>
    </div>
    <input type="hidden" :value="data" ref="datafield"/>
    <div class="copy-icon" @click.stop="copyToClipboard()">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-copy"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CopyableText',
  props: {
    data: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      copyAnimation: false
    }
  },
  methods: {
    copyToClipboard() {
      let copy = this.$refs.datafield
      copy.setAttribute('type', 'text') 
      copy.select()
      document.execCommand("copy");
      copy.setAttribute('type', 'hidden') 
      this.copyAnimation = true
      setTimeout(() => this.copyAnimation = false, 1000)
    }
  },
}
</script>

<style lang="scss" scoped>

.copyabletext-container{
    display: inline-block;
}

.copyable-text {
  display: inline-block;
}
.copy-icon {
  cursor: pointer;
  margin-left: 8px;
  color: #e7196f;
  float: right;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(#e7196f, 0.8);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(#e7196f, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(#e7196f, 0);
  }
}

.pulse {
  animation: pulse;
  animation-duration: 1s;
}
</style>
<template>
  <div class="modal-container">
    <div class="modal-content" @click.stop>
      <div v-if="!unclosable" class="close-icon" @click="$emit('close')">
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
          <path fill-rule="evenodd" d="M12.5 0C19.404 0 25 5.596 25 12.5S19.404 25 12.5 25 0 19.404 0 12.5 5.596 0 12.5 0zm0 2C6.701 2 2 6.701 2 12.5S6.701 23 12.5 23 23 18.299 23 12.5 18.299 2 12.5 2zm3.73 5.737c.406-.292.975-.255 1.34.11.362.362.4.927.114 1.332l-.079.098-3.43 3.431 3.395 3.394.11.129c.29.405.254.974-.11 1.339-.363.362-.928.4-1.333.114l-.097-.079-3.432-3.43-3.394 3.394-.128.11c-.406.292-.975.255-1.34-.11-.362-.362-.4-.927-.113-1.332l.078-.098 3.43-3.431-3.394-3.393-.11-.13c-.291-.405-.255-.973.11-1.338.363-.363.927-.4 1.332-.114l.098.078 3.431 3.43 3.394-3.394.128-.11z"/>
        </svg>
      </div>
      <div class="slot-content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    unclosable: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      key: "value"
    }
  },
  created() {
    document.addEventListener('keydown', (e) => { if(e.key == 'Escape') this.$emit('close') })
  },
  methods: {
    name() {

    }
  },
}
</script>

<style lang="scss" scoped>

.modal-container {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(black, 0.8);
  z-index: 9998;
  cursor: default;
  color: black;
}

.close-icon {
  position: absolute;
  top: -50px;
  right: -50px;
  color: white;
  fill: white;
  font-weight: bold;
  padding: 20px;
  z-index: 9999;
  cursor: pointer;
}

.modal-content {
  z-index: 9998;
  min-width: 50vw;
  min-height: 50vh;
  max-width: 90vw;
  max-height: 90vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.slot-content {
  overflow-y: scroll;
  max-height: inherit;
  background-color: white;
  border-radius: 5px;
}

@media only screen and (max-width: 600px) {
  .modal-content {
    min-width: 80vw;
    max-width: 80vw;
    min-height: 30vh;
  }
}
</style>